import axios from 'axios'
import selectedEnv from '../Universal_CONFIG';



export const SET_INFO_RESPONSE = "SET_INFO_RESPONSE";
export const SET_JURIS_RESPONSE = "SET_JURIS_RESPONSE";
export const SET_BUTTONS_RESPONSE = "SET_BUTTONS_RESPONSE";

export const SET_DYNO_USE_REGULATIONS_FILTERS = "SET_DYNO_USE_REGULATIONS_FILTERS";

export function saveInfoResponse(data) {
    return {
      type: SET_INFO_RESPONSE,
      payload: data,
    };
  }

export function saveJurisResponse(data) {
    return {
      type: SET_JURIS_RESPONSE,
      payload: data,
    };
  }
export function saveButtonsResponse(data) {
  return {
    type: SET_BUTTONS_RESPONSE,
    payload: data,
  };
}
export function saveDynoUserRegulationsFilters(data) {
  return {
    type: SET_DYNO_USE_REGULATIONS_FILTERS,
    payload: data,
  };
}
export function getJurisData(){
  const headers = { 'region': selectedEnv.REACT_APP_REGION }
  return (dispatch) =>{
    return axios.get(selectedEnv.REACT_APP_API_URL + "/api/filter/get-juris-contact",{headers: {
      'region': selectedEnv.REACT_APP_REGION
  }})
      .then(resp=>{
        console.log("jojo",resp)
        dispatch(saveJurisResponse(resp.data))
      })
  }
}

export function getRegionsData(){
  return (dispatch) =>{
    return axios.get(selectedEnv.REACT_APP_API_URL + "/api/filter/get-regions",{headers: {
      'region': selectedEnv.REACT_APP_REGION
  }})
      
      .then(resp=>{
        console.log("jojo",resp)
        dispatch(saveButtonsResponse(resp.data))
      })
  }
}