import selectedEnv from '../Universal_CONFIG';
import {
  COVERAGE_FILTER,
  EXISTING_STRUCTURE_FILTER,
  FAR_FILTER,
  HEIGHT_FILTER,
  JURIS_FILTER,
  LAND_LOT_SIZE,
  TOTAL,
  UNITS_FILTER
} from "../constants";
import { setFiltersMaxValues } from "../utils/Utils";
import { setDataWindow, setDataWindowContent } from './actions_datawindow';
import { getJurisData, getRegionsData, saveDynoUserRegulationsFilters } from './actions_dyno_info';
import { setFilterPrimeAccountNumbers } from "./actions_search";
import { setAlertMessage, setLoader, setUserLoginError } from './actions_user';

export const SET_FILTER_DEFAULT_VALUE = "SET_FILTER_DEFAULT_VALUE";
export const SET_LOCATION_SPECIFCS_VALUES = "SET_LOCATION_SPECIFCS_VALUES"
export const SET_FILTER_CURRENT_VALUE = "SET_FILTER_CURRENT_VALUE";
export const RESET_ALL_CURRENT_FILTERS = "RESET_ALL_CURRENT_FILTERS";
export const SET_FILTER_VALUES = "SET_FILTER_VALUES";
export const UPDATE_FILTER_BY_NAME = "UPDATE_FILTER_BY_NAME";
export const UPDATE_RESULT_FILTER = "UPDATE_RESULT_FILTER";
export const UPDATE_SELECTED_PROPERTY = "UPDATE_SELECTED_PROPERTY";
export const UPDATE_FILTER_PAYMENT = "UPDATE_FILTER_PAYMENT";
export const SET_SAVED_FILTER = "SET_SAVED_FILTER";
export const SET_FILTER_PLAN = "SET_FILTER_PLAN";
export const SET_FILTER = "SET_FILTER";
export const SET_SHOW_FILTER_FOLDER = "SET_SHOW_FILTER_FOLDER";
export const SET_DEFAULT_FILTER = "SET_DEFAULT_FILTER";
export const SET_ZONE_VALUES = "SET_ZONE_VALUES";

// TODO: NEED TO ADD LOT_TYPE | FAR_LOT | FAR_LOT_BONUS LATER


// This is for zone and zone_cat
export function getSelectFilters(type) {
  return (dispatch) => {
    return fetch(selectedEnv.REACT_APP_API_URL + "/api/taxlotswzoning/range/" + type)
      .then((resp) => resp.json())
      .then((resp) => {
        var dataToReturn = {
          type: type,
          data: resp.values.filter((value) => value !== null),
        };
        dispatch(setFilterDefaultValue(dataToReturn)); // Here we are also passing the TYPE so we can set it dynamically in the reducer
      });
  };
}

// This is for ones where we need max and min values
export function getRangeFilters(type, table) {
  let filters = [];
  let tableType = "taxlotswzoning";

  if (table) {
    tableType = table;
  }

  return (dispatch) => {
    return fetch(selectedEnv.REACT_APP_API_URL + `/api/${tableType}/range/` + type)
      .then((resp) => resp.json())
      .then((resp) => {
        resp.values.map((value) => value !== null && filters.push(Math.floor(value)));
        let sortedFilters = filters.sort((a, b) => a - b);
        let range = [sortedFilters[0], sortedFilters[sortedFilters.length - 1]];

        if (type === "htlim_adjx" || type === "htlim_bonx") {
          range = [sortedFilters[0], 500];
        } else if (type === "umax_adjx_valu") {
          range = [sortedFilters[0], 300];
        } else if (type === "umax_bonx_valu") {
          range = [sortedFilters[0], 200];
        } else if (type === "envp_grnd_lot_area" || type === "envp_grnd_ftpt_max") {
          range = [sortedFilters[0], 200000];
        } else if (type === "envp_bldg_max_area" || type === "envp_bldg_max_res") {
          range = [sortedFilters[0], 1000000];
        }

        var dataToReturn = {
          type: type,
          data: range,
        };
        dispatch(setFilterDefaultValue(dataToReturn));
      });
  };
}

export function applyFilter(object, token) {
  return (dispatch) => {
    return fetch(`${selectedEnv.REACT_APP_API_URL}/api/filter/search`, {
      method: "POST",
      headers:{
        region:localStorage.getItem("sub-city")
      },
      body: JSON.stringify(object),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (Object.keys(resp).includes("tokenExpired")) {
          window.location.reload(false);
        } else if (Object.keys(resp).includes("message")) {
          Object.keys(resp).includes("availableIn") ? dispatch(setUserLoginError("Request limit exceeded. Please try again in " + resp.availableIn + " sec!")):dispatch(setUserLoginError(resp?.message))
          dispatch(setFilterPrimeAccountNumbers(resp?.primaccnum ?? []))
          dispatch(updateResult(resp));
          dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isFilter: true } }));
          dispatch(setDataWindowContent({ show: { showData: 'see_result', showControl: 'filters' } }));
          // dispatch(setUserLoginError(resp.message))
        }
        else {
          dispatch(setFilterPrimeAccountNumbers(resp?.primaccnum ?? []))
          dispatch(updateResult(resp));
          dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isFilter: true } }));
          dispatch(setDataWindowContent({ show: { showData: 'see_result', showControl: 'filters' } }));
        }
        dispatch(setLoader(false));
      })
      .catch((error) => {
        dispatch(setLoader(false));
      });
  };
}

export function getSaveFilters(user) {
  return (dispatch) => {
    return fetch(`${selectedEnv.REACT_APP_API_URL}/api/filter/${user}`, {
      method: "GET",
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (Object.keys(resp).includes("tokenExpired")) {
          window.location.reload(false);
        } else {
          dispatch(setSavedFilters(resp))
          dispatch(setDataWindow({ dataWindow: true, content: { isContact: true, contactInfo: {}, choosePlan: {}, isPayment: false } }));
          dispatch(setDataWindowContent({ show: { showData: 'saved-filter', showControl: 'dashboard' } }));
          // dispatch(setAlertMessage((resp?.total_count ?? 0)+ " Records Found."));
          dispatch(setLoader(false));
        }
      })
      .catch((error) => {
        dispatch(setLoader(false));
      });
  };
}


export function deleteSaveFilters(id) {
  return (dispatch) => {
    return fetch(`${selectedEnv.REACT_APP_API_URL}/api/filter/${id}`, {
      method: "DELETE",
    }).then(() =>
      dispatch(setLoader(false)),
      dispatch(setAlertMessage('Your filter has been delete successfully'))
    )
  }
}

export function setDeleteFilter(resp) {
  return (dispatch) => {
    dispatch(setSavedFilters(resp));
  }
}

export function saveFilter(object, token) {
  return (dispatch) => {
    return fetch(`${selectedEnv.REACT_APP_API_URL}/api/filter/`, {
      method: "POST",
      body: JSON.stringify(object),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(setAlertMessage("Filter has been saved."))
        dispatch(setLoader(false));
      })
      .catch((error) => {
        dispatch(setLoader(false));
      });
  };
}


export function getFiltersData(name,upd=null) {
  const headers = { 'region': localStorage.getItem("sub-city") }

  return (dispatch) => {
    dispatch(setLoader(true))
    dispatch(getRegionsData())
    dispatch(getJurisData())
    return fetch(`${selectedEnv.REACT_APP_API_URL}/api/filter/get-slider-values`, {
      method: "GET",
      headers:{
        region:localStorage.getItem("sub-city")
      }
    })
      .then((resp) => resp.json())
      .then((resp) => {
        const respp = resp
        fetch(`${selectedEnv.REACT_APP_API_URL}/api/filter/get-zone-values`, { headers })
          .then(response => response.json())
          .then(data => {
           
            fetch(`${selectedEnv.REACT_APP_API_URL}/api/filter/get-location-values`, {
              headers,
            })
              .then((response) => response.json())
              .then((res) => {
                const locationSpecifics = res;


                // Update Lot Type filter with API response values
                const lotTypes = data.lot_types?.map(lotType => {
                  return {
                    type: "checkbox",
                    default: false,
                    label: lotType,
                    name: lotType,
                    action: false,
                    column_name: "lot_type",
                  }
                });
                  fetch(`${selectedEnv.REACT_APP_API_URL}/api/filter/get-use-regulation-values`, {
                    headers,
                  } )
                  .then((useRegulationsResponse) => useRegulationsResponse.json())
                  .then((useResp=>{
                    console.log("useResp",useResp)
                    dispatch(saveDynoUserRegulationsFilters(useResp))
                    const lotTypeFilter = LAND_LOT_SIZE.find(filter => filter.name === 'lot_type');
                    lotTypeFilter.checkbox = [
                      ...lotTypes
                    ];
                    const zoning = [
                      {
                        type: "checkbox",
                        label: "Select all/none", 
                        name: "zone_cat1",   
                        checkbox: data.zone_cat1,
                      },
                      {
                        type: "checkbox",
                        label: "Select all/none",
                        hide: true,
                        name: "zone_cat2",  
                        checkbox: data.zone_cat2,
                      },
                      { 
                        column_name: "zone",
                        type: "checkbox",
                        label: "Select all/none",
                        name: "zones", 
                        checkbox: data.zones,
                      },
                    ];
                    //set Juris filter dynamic according to sub-city
                    if(selectedEnv.REACT_APP_SUB_REGION){
                      JURIS_FILTER[0].checkbox.push({
                        type: "checkbox",
                        label: `City of ${selectedEnv.REACT_APP_SUB_CITY_NAME.split(",")[0]}`,
                        name: `city_of_${selectedEnv.REACT_APP_SUB_REGION}`,
                        action: false,
                        column_name: "juris"
                    })
                    }
                    let filter = setFiltersMaxValues(
                      JURIS_FILTER,
                      zoning,
                      locationSpecifics,
                      LAND_LOT_SIZE,
                      useResp,
                      HEIGHT_FILTER,
                      FAR_FILTER,
                      EXISTING_STRUCTURE_FILTER,
                      COVERAGE_FILTER,
                      UNITS_FILTER,
                      TOTAL,
                      // locationSpecifics,
                      respp);
                    dispatch(setFilterValues(filter));
                    dispatch(setDefaultFilter(filter));
                    if(upd){
                      dispatch(UpdateFilter(upd));
                    }
    dispatch(setLoader(false))

                  }))

              

              });
          })
      }
      
      )
      .catch((error) => {

      });
      
  };
}
export function setZoneValue(data) {
  return {
    type: SET_ZONE_VALUES,
    payload: data,
  }
}
export function setLocationSpecifics(data) {
  return {
    type: SET_LOCATION_SPECIFCS_VALUES,
    payload: data,
  }
}
export function setFilterDefaultValue(filtersAndType) {
  return {
    type: SET_FILTER_DEFAULT_VALUE,
    payload: filtersAndType,
  };
}

export function setFilterCurrentValue(filterType, filterValue) {
  return {
    type: SET_FILTER_CURRENT_VALUE,
    payload: {
      type: filterType,
      data: filterValue,
    },
  };
}

export function resetAllCurrentFilters(data) {
  return {
    type: RESET_ALL_CURRENT_FILTERS,
    payload: data,
  };
}

export function setFilterValues(data) {
  return {
    type: SET_FILTER_VALUES,
    payload: data,
  }
}

export function UpdateFilter(data) {
  return {
    type: UPDATE_FILTER_BY_NAME,
    payload: data
  }
}

export function updateResult(data) {
  return {
    type: UPDATE_RESULT_FILTER,
    payload: data
  }
}

export function updateSelectedProperty(data) {
  return {
    type: UPDATE_SELECTED_PROPERTY,
    payload: data,
  }
}

export function updateFilterPayment(data) {
  return {
    type: UPDATE_FILTER_PAYMENT,
    payload: data,
  }
}
export function setFilterPlan(data) {
  return {
    type: SET_FILTER_PLAN,
    payload: data,
  }
}
export function setSavedFilters(data) {
  return {
    type: SET_SAVED_FILTER,
    payload: data
  }
}
export function seRunFilter(data) {
  return {
    type: SET_FILTER,
    payload: data
  }
}

export function setShowFilterFolder(data) {
  return {
    type: SET_SHOW_FILTER_FOLDER,
    payload: data
  }
}

export function setDefaultFilter(data) {
  return {
    type: SET_DEFAULT_FILTER,
    payload: data,
  }
}