import { aus_env } from "./AUS_CONFIG";
import { la_env } from "./LA_CONFIG";
import { pdx_env } from "./PDX_CONFIG";
import { san_env } from "./SAN_CONFIG";
import { sea_env } from "./SEA_CONFIG";

let selectedEnv = '';
if (window.location.href.includes("sea")) {
  selectedEnv = sea_env;
} else if (window.location.href.includes("san")) {
  selectedEnv = san_env;
} else if (window.location.href.includes("aus")) {
  selectedEnv = aus_env;
} else if (window.location.href.includes("pdx")) {
  selectedEnv = pdx_env;
} else if (window.location.href.includes("la")) {
  selectedEnv = la_env;
} else {
  selectedEnv = la_env;
}
// console.log("env is",selectedEnv)
export default selectedEnv