import React from "react";
import { Grid } from "semantic-ui-react";
import "../../../../css/zone.scss";
import { useSelector } from "react-redux";
import { JurisCheckboxUpdate, checkboxUpdate } from "../../../../utils/Utils";
import {
  UpdateFilter,
  getFiltersData,
} from "../../../../actions/actions_filters";
import selectedEnv from "../../../../Universal_CONFIG";

const LSCheckbox = ({
  dispatch,
  info,
  columns,
  selectedFilter,
  style,
  lineStyle,
}) => {
  const { show } = useSelector((state) => state.dataWindow);
  let chk = true;

  //console.log("shks",mem)
  const onChangeSelect = (e) => {
    let { name, checked } = e.target;
    console.log("name", name, "checked", checked, "show", show);

    let currentFilter = show.showData;
    let UpdatedFilter = {
      name: currentFilter,
      filterData:
        show.showData === "jurisdiction"
          ? JurisCheckboxUpdate(currentFilter, selectedFilter, name, checked)
          : checkboxUpdate(currentFilter, selectedFilter, name, checked),
    };
    //    dispatch(UpdateFilter(UpdatedFilter));

    console.log("nicky", UpdatedFilter);
    if (show.showData === "jurisdiction") {
      if (checked) {
        if (name === `city_of_${selectedEnv.REACT_APP_REGION}`) {
            localStorage.setItem("sub-city",selectedEnv.REACT_APP_REGION)
          dispatch(getFiltersData("portland", UpdatedFilter));
          // dispatch(UpdateFilter(UpdatedFilter));
        } else {
         if(selectedEnv.REACT_APP_SUB_REGION){
          localStorage.setItem("sub-city",selectedEnv.REACT_APP_SUB_REGION)
          dispatch(getFiltersData("seattle", UpdatedFilter));
         }
          //  dispatch(UpdateFilter(UpdatedFilter));
        }
      } else {
        dispatch(UpdateFilter(UpdatedFilter));
      }
    } else {
      dispatch(UpdateFilter(UpdatedFilter));
    }
  };
  return (
    <>
      <Grid columns={columns} style={style ?? {}}>
        {info?.checkbox &&
          info.checkbox.map((field) => {
            return (
              <Grid.Column style={{ padding: "7px 0" }}>
                <div className="ui checkbox">
                  <input
                    type={field.type}
                    name={field.name}
                    required={field.required}
                    onChange={onChangeSelect}
                    checked={field.action}
                  />
                  <label>{field.label}</label>
                </div>
              </Grid.Column>
            );
          })}
      </Grid>
      {/* <hr className="horizontal-row" style={lineStyle ?? {}} />  */}
    </>
  );
};

export default LSCheckbox;
